
import React, { useContext } from "react";
import { GlobalState } from "../Context/Store";
import Field from "../Component/Field";

export default function Summary() {
  let state = useContext(GlobalState);
  let info = state.getInfo;
  let {users}    = state.getUser;
  let {course}   = state.getCourse;
  let {setTab}   = state.getTab;
  let {setValue} = state.getValue;
  let { emails } = state.getEmail;
  let { setInput } = state.getInput;
  let summary    = info.getUserSummary(users);
  let display_as = [];
  let courses    = [];

  let handleClick = (e)=>{
    let email = emails.find((x) => x.value === e);
   
    if (email) {
      let user = users.find((x) => x.id === email.id);
      let _user = {...user,email:e}
   
      setValue(_user);
      setInput(email.name);
      setTab("individual");  
    }    
  }

  
  course.map(e=>{   
    display_as.push(e.display_as);
    return display_as;
  })
  course.map(e=>{   
    courses.push(e.name);
    return courses;
  })

  return (
    <>
      <Field
        title="Email"
        type="list"
        name="email"
        onClick= {handleClick}
        data={info.occurence(summary.email)} />
      <div className="solidbg pad">
        <div>Contact Details</div>
      </div>
      <Field
        title="First Name"
        type="list"
        name="first_name"
        data={summary.first_name}
      />
      <Field
        title="Last Name"
        type="list"
        name="last_name"
        data={summary.last_name}
      />
      <Field
        title="Phone Number"
        type="list"
        name="phone"
        data={info.occurence(summary.phone)}
      />
      <Field title="Age" type="list" name="age" data={summary.age} />
      <Field
        title="Gender"
        type="pie"
        id="gender"
        name="gender"
        labels={["Male", "Female"]}
        data={summary.gender}
      />
      <Field
        title="Address"
        type="list"
        name="address"
        data={summary.address}
      />
      <Field
        title="Country"
        type="bar"
        id="country"
        name="country"
        labels={state.country}
        data={summary.country}
      />
      <Field title="State" type="list" name="state" data={summary.state} />
      <div className="solidbg pad">
        <div>Career and Skill</div>
      </div>
      <Field
        title="Current occupation"
        type="pie"
        id="occupation"
        name="occupation"
        labels={[
          "Student",
          "Unemployed",
          "Employed (Part time)",
          "Employed (Full time)"
        ]}
        data={summary.occupation}
      />
       <Field
        title="Highest Educational Qualification"
        type="pie"
        id="education"
        name="education"
        labels={[
          "Primary School Certificate",
          "Secondary School Certificate (SSCE)",
          "Diploma / Technical Certificate",
          "Graduate (HND / B.ED / BSc / B.Tech /B.A / B.Law)",
          "Postgraduate (MSc / M.A / M.Eng)",
          "Doctorate (PhD)"
        ]}
        data={summary.education}
      />
       <Field
        title="Which Course Are you Interested in?"
        type="bar"
        id="course"
        name="course"
        alias={display_as}
        labels={courses}
        data={summary.course}
      />
      <Field
        title="What is your knowledge Level of Programming"
        type="pie"
        id="program_level"
        name="program_level"
        labels={["Novice", "Beginner", "Intermediate", "Professional"]}
        data={summary.program_level}
      />
      <Field
        title="What class option are you interested in? (Physical Classes at the Academy in Abuja)"
        type="pie"
        id="session"
        name="session"
        labels={[
          "WEEKDAY",
          "WEEKEND"
        ]}
        data={summary.session}
      />
       <Field
        title="How did you hear about us"
        type="list"
        name="more_about_you"
        data={summary.more_about_you}
      />
       <Field
        title="How did you hear about us"
        type="list"
        name="hear_about_us"
        data={summary.hear_about_us}
      /> 
    </>
  );
}
