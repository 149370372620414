let HOST;
let Route;

switch(process.env.REACT_APP_MODE.toUpperCase()){
  case "DEV":
    HOST = "http://localhost:3004/v2";
    break;
  case "STAGE":
    HOST = "https://reworkacademy.co/app/v2";
    break;
  case "PROD":
    HOST = "https://api.reworkacademy.co/v2";
    break;
  default:
    HOST = "http://localhost:3004/v2";
    break;

}

// console.log(process.env.REACT_APP_MODE.toUpperCase())

Route = {
  MODE: true,
  LOGIN: `${HOST}/auth/login`,
  INTEREST: `${HOST}/interests`,
  COURSE: `${HOST}/courses/manager/active`,
  FORM_STATUS: `${HOST}/interests/formstatus`,
  CREATE_STUDENT: `${HOST}/students/register-form`,
  DELETE_RESPONSE: `${HOST}/interests/:id/permanent`,
  REGISTERED_EMAILS: `${HOST}/interests/emails`,
};
export default Route;
