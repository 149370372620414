import { StrictMode } from "react";
import {createRoot} from "react-dom/client";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { CookiesProvider } from "react-cookie";
import App from "./App";

if (process.env.REACT_APP_MODE === 'PROD') {
  disableReactDevTools();
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <CookiesProvider>
    <App />
    </CookiesProvider>
  </StrictMode>
);
