import "./styles.css";
// import Registration from "./Pages/Registration";
import Response from "./Pages/Response";
import Excel from "./Pages/ExcelHandson";
import Login from './Pages/Login';
import { Store } from "./Context/Store";
import { BrowserRouter, Routes, Route } from "react-router-dom";

export default function App() {
  return (
    <>
      <Store>
        <BrowserRouter>
          <Routes>
            <Route element={<Response />} path="/" />
            <Route element={<Login />} path="/login" />
            <Route element={<Excel />} path="/sheet" />
          </Routes>
        </BrowserRouter>
      </Store>
    </>
  );
}
