import {
  Chart,
  LineElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PieController,
  ArcElement,
  BarController,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  DoughnutController
} from "chart.js";
import { useEffect } from "react";
Chart.register({
  LineElement,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  BarController,
  PieController,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  DoughnutController
});

function BarChart({ id, labels, type, data, colors, cutout }) {



  useEffect(() => {
    const ctx = document.getElementById(id);
    const chart = Chart.getChart(id);

    if (chart !== undefined) chart.destroy();
  

    new Chart(ctx, {
      type,
      data: {
        labels,
        datasets: [
          {
            data,
            label: "3",
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 0.2
          }
        ]
      },
      options: {
        // indexAxis: 'y',
        cutout: cutout, // This adjusts the thickness
        borderRadius: 5,
        offset: 5,
        aspectRatio: 3, //Centralized the diagram
        scales: {
          x: {
            display: true,
            grid: {
              display: false
            }
          },
          y: {
            display: false,
            beginAtZero: true,
            grid: {
              display: false
            }
          }
        },
        plugins: {
          maintainAspectRatio: false,
          tooltip: {
            enabled: true
          },
          datalabels: {
            display: true,
            color: "#fff",
            align: "center",
            padding: {
              right: 200
            },
            // legend: {
            //   position: 'left',
            // },
            labels: {
              padding: { top: 10 },
              title: {
                font: {
                  // family:"monospace",
                  weight: "normal"
                }
              },
              value: {
                color: "#fff"
              }
            },
            formatter: function (value) {
              let total = data.reduce((a, b) => a + b);
              let percent = Number((value * 100) / total).toFixed(1);
              if (value) return percent + "%";
              return "";
            }
          },
          responsive: true,
          legend: {
            display: false,
            position: "right",
            labels: {
              color: "#333",
              boxWidth: 12,
              boxHeight: 8,
              usePointStyle: true,
              fontFamily: "6px consolas"
            }
          }
        }
      }
    });
  }, [colors,cutout,data,id,labels,type]);
  return <canvas width="200" height="200" id={id}></canvas>;
}
export default BarChart;
