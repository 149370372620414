import axios from "axios";
import Route from "./Route";
import users from "../data/users.json";
import emails from "../data/emails.json";
import courses from "../data/courses.json";

function Action() {

}

String.prototype.bind = function (...args) {
  let value = this.valueOf();
  let arr = value.match(/:[a-zA-Z_]+/g);
  for (let i in args) {
    value = value.replace(arr[i], args[i])
  }

  return value;
}

String.prototype.query = function (args) {
  let value = this.valueOf();
  let str = "?"
  for (let key in args) {
    str += `${key}=${args[key]}&`
  }
  if (str.endsWith('&')) {
    let lindex = str.lastIndexOf('&');
    str = str.substr(0, lindex)
  }

  return value + str;
}


class Info {
  constructor(mode) {
    if (mode) Route.MODE = mode;
  }

  get getData() {
    return {
      id: "",
      email: "",
      first_name: "",
      last_name: "",
      other_name: "",
      phone: "",
      birth_date: "",
      age: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      occupation: "",
      education: "",
      course: "",
      program_level: "",
      session: "",
      more_about_you: "",
      hear_about_us: "",
      ref_code: "",
      created_at:""
    };
  }

  get getFormStatus() {
    return new Promise((res, err) => {
      if (Route.MODE) {
        axios
          .get(Route.FORM_STATUS)
          .then(info => res(info.data))
          .catch((e) => err(e))
      } else {
        res({ active: false, msg: "" });
      }
    })
  }

  login(payload){
    return new Promise((res,err) => {
      if(Route.MODE){
        axios
        .post(Route.LOGIN,payload)
        .then(info => res(info.data))
        .catch((e)=> err(e))
      }else{
        res({ active: false, msg: "" });
      }
      
    })
  }

  deleteFormResponse(id) {
    return new Promise((res, err) => {
      if (Route.MODE) {
        axios
          .delete(Route.DELETE_RESPONSE.bind(id))
          .then(info => {
            res(info.data)
          })
          .catch((e) => err(e));
      } else {
        res(id);
      }
    })
  }

  get getEmail() {
    return new Promise((res, err) => {

      if (Route.MODE) {
        axios
          .get(Route.REGISTERED_EMAILS)
          .then((info) => {
            Action.emails = info.data;
            res(info.data);
          })
          .catch((e) => err(e));
      } else {
        res(emails);
      }
    })

  }

  get getUser() {
    return new Promise((res, err) => {
      if (Route.MODE) {
        axios
          .get(Route.INTEREST)
          .then((info) => {
            Action.users = info.data;
            res(info.data)
          })
          .catch((e) => err(e));
      } else {
        res(users);
      }

    })
  }

 
  get getCourses(){
    
    return new Promise((res, err) => {

      if (Route.MODE) {
        axios
          .get(Route.COURSE)
          .then((info) => {
            Action.courses = info.data;
            res(info.data);
          })
          .catch((e) => err(e));
      } else {
        res(courses);
      }
    })
  }

  getCSV() {
    let arr = [];

    function csv(users) {
      for (let i = 0; i < users.length; i++) {
      
          let u = users[i];

          let options  = { year: 'numeric',month: '2-digit',day: '2-digit',hour: 'numeric',minute: 'numeric',hour12: true };
          let result   = new Intl.DateTimeFormat('en-US', options).format(u.created_at);

          u.date_submitted = result;

          delete u['form_id'];
          delete u['id'];
          delete u['created_at'];

          if (arr.length === 0) {
            let keys = Object.keys(u)
            keys.unshift("S/N")
            arr[0] = keys;
          }
          let a = Object.values(u)
          let d = []
          a.map(e => {
            if(typeof(e) === "string" && e?.includes(",")){
              e = `"${e}"`
            }
            if(typeof(e) === "string" && e?.includes("\n")){
              e = e.replace(/\n/g," ")
            }

            return d.push(e)
          })
          d.unshift(i+1) 
          arr[i + 1] = d;
          
         
        
      }
      return arr;
    }

    return new Promise((res, err) => {
      if (Route.MODE) {
        axios
          .get(Route.INTEREST)
          .then((info) => {
            res(csv(info.data))
          })
          .catch((e) => err(e));
      } else {
        res(csv(users))
      }
    })

  }

  setRecord(data) {
    if (Route.MODE) {
      axios
        .post(Route.INTEREST, data)
        .then((res) => {
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log(data);
    }
  }

 

  // getCourses(c){
  //   let courses = {
  //     "Full Stack Web Development with NodeJs (9 Months Training)":"Fullstack web programming with Node.js",
  //     "Data Analytics with Excel and PowerBi (3 Months Training)":"Data Analytics with Excel and PowerBi",
  //     "Data Science with Python (7 Months)":"Data Science with Python",
  //     "Front-End Development with React (5 Months)":"Front-End Development with React",
  //     "Python Programming":"Python Programming",
  //     "UI/UX Design (3 Months)":"UI/UX Design",
  //     "Android App Development (3 Months)":"Android App Development"
  //   }
  //   return courses[c];
  // }

  getLearnMode(session){
    let learning_mode = "virtual";
    if(session.includes("physical")) learning_mode = "physical";
    return learning_mode;
  }

  genPassword(){
    return "1234455"
  }

  setStatus(value) {
  
    console.log(value)
    if(value.generated) return value;    
  
    let payload = {
        "interest_id":value.id,
        "first_name": value.first_name,
        "last_name": value.last_name,
        "other_name": value.other_name,
        "phone": value.phone,
        "gender": value.gender,
        "birth_date":value.birth_date,
        "email": value.email,
        "passwd":this.genPassword(),
        "learning_mode":this.getLearnMode(value.session.toLowerCase().trim()),
        // "course":this.getCourses(value.course)     
    }
    console.log(Route.CREATE_STUDENT)
  // if (Route.MODE) {
  //     axios
  //       .post(Route.CREATE_STUDENT, payload)
  //       .then((res) => {
  //         console.log(res.data);

  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } else {
  //     console.log();
  //   }

    value.generated = true;

    return value
  }

  summary(data) {
    let arr = {};
    // for (let i = 0; i < data.length; i++) {
    //   let d = data[i];
    //   for (let j in d) {
    //     if (arr[j]) {
    //       if (d[j] && !arr[j].includes(d[j])) {
    //         arr[j].push(d[j]);
    //       }
    //     } else {
    //       arr[j] = [d[j]];
    //     }
    //   }
    // }
    return arr;
  }

  summarize(data) {
    let arr = {};
    for (let i = 0; i < data?.length; i++) {
      let d = data[i];
      if (arr[d]) {
        arr[d]++;
      } else {
        arr[d] = 1;
      }
    }
    return arr;
  }

  getUserSummary(result) {
    let data = result ? result : Action.users;
    let arr = {};

    for (let i = 0; i < data?.length; i++) {
      let d = data[i];
      for (let j in d) {
        if (arr[j]) {
          if (d[j]) {
            arr[j].push(d[j]);
          }
        } else {
          arr[j] = [d[j]];
        }
      }
    }
    return arr;
  }

  occurence(data) {

    let count = {}
    let arr = []
    for (let i = 0; i < data?.length; i++) {
      let d = data[i];
      if (!count[d]) {
        count[d] = 1
        arr.push(d)

      } else {
        let c = 0;
        let total = count[d];
        total++;
        c = total - 1;
        count[d] = total;
        arr.push(`${d} (${c})`)
      }
    }
    return arr;
  }


}
export default Info;
