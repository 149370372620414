import Field from "../Component/Field";
import { GlobalState } from "../Context/Store";
import React, { useContext } from "react";

export default function Individual() {

  let state    = useContext(GlobalState);
  let {course} = state.getCourse;
  let courses  = [];
  let d        = state.getValue?.value.created_at;
  let date     = new Date(d || Date.now());
  let options  = { year: '2-digit',month: '2-digit',day: '2-digit',hour: 'numeric',minute: 'numeric',hour12: true };
  let result   = new Intl.DateTimeFormat('en-US', options).format(date);
 
  course.map(e => {
    return courses.push({
      name:"course",
      value:e.name
    })
  })

  return (
    <>
      <Field
        state={state.getValue}
        title="Email"
        type="text"
        name="email"
        required={true}
        disabled={true}
      />

      <div className="solidbg pad">
        <div>Contact Details</div>
      </div>
      <Field
        state={state.getValue}
        title="First Name"
        type="text"
        name="first_name"
        required={true}
        disabled={true}
      />
      <Field
        state={state.getValue}
        title="Last Name"
        type="text"
        name="last_name"
        required={true}
        disabled={true}
      />
      <Field
        state={state.getValue}
        title="Other Name"
        type="text"
        name="other_name"
        required={true}
        disabled={true}
      />
      <Field
        state={state.getValue}      
        title="Phone Number"
        type="number"
        name="phone"
        required={true}
        disabled={true}
      />
      <Field
        state={state.getValue}
        title="Date Of Birth"
        type="date"
        name="birth_date"
        required={true}
        disabled={true}
      />
      <Field
        state={state.getValue}
        title="Age"
        type="text"
        name="age"
        required={true}
        disabled={true}
      />
      <Field
        state={state.getValue}
        title="Gender"
        type="radio"
        name="gender"
        required={true}
        disabled={true}
        data={[
          {
            name: "gender",
            value: "Male"
          },
          {
            name: "gender",
            value: "Female"
          }
        ]}
      />
      <Field
        state={state.getValue}
        title="Address"
        type="text"
        name="address"
        required={true}
        disabled={true}
      />
      <Field
        state={state.getValue}
        title="Country"
        type="text"
        name="country"
        required={true}
        disabled={true}
      />
      <Field
        state={state.getValue}
        title="State"
        type="text"
        name="state"
        required={true}
        disabled={true}
      />

      <div className="solidbg pad">
        <div>Career and Skill</div>
      </div>
      <Field
        state={state.getValue}
        title="Current occupation"
        type="text"
        name="occupation"
        required={true}
        disabled={true}
      />

      <Field
        state={state.getValue}
        title="Highest Educational Qualification"
        type="text"
        name="education"
        required={true}
        disabled={true}
      />

      <Field
        state={state.getValue}
        title="Which Course Are you Interested in"
        type="radio"
        name="course"
        required={true}
        disabled={true}
        data={courses}
      />

     <Field
        state={state.getValue}
        title="Learning Mode"
        type="radio"
        name="learning_mode"
        required={true}
        disabled={true}
        data={[
          {
            name: "learning",
            value: "PHYSICAL"
          },
          {
            name: "learning",
            value: "VIRTUAL"
          },
          {
            name: "learning",
            value: "SELF_PACE"
          }
        ]}
      />
      
      <Field
        state={state.getValue}
        title="What class option are you interested in? (Physical Classes at the Academy in Abuja)"
        type="radio"
        name="session"
        required={true}
        disabled={true}
        data={[
          {
            name: "session",
            value:
              "WEEKDAY"
          },
          {
            name: "session",
            value:
              "WEEKEND"
          }
        ]}
      />

    <Field
        state={state.getValue}
        title="Accessment Date"
        type="text"
        name="assessment_date"
        required={true}
        disabled={true}
        
      />
       <Field
        state={state.getValue}
        title="Accessment Time"
        type="text"
        name="assessment_time"
        required={true}
        disabled={true}
        
      />

      <Field
        state={state.getValue}
        title="What is your knowledge Level of Programming"
        type="select"
        name="program_level"
        required={true}
        disabled={true}
        data={[
          { name: "novice", value: "Novice" },
          { name: "beginner", value: "Beginner" },
          { name: "intermediate", value: "Intermediate" },
          { name: "professional", value: "Professional" }
        ]}
      />


      <Field
        state={state.getValue}
        title="Let us know you more. Why do you want to become a full-stack developer? What makes you exceptional?"
        name="more_about_you"
        type="textarea"
        required={true}
        disabled={true}
      />

      <Field
        state={state.getValue}
        title="How did you hear about us"
        type="text"
        name="hear_about_us"
        disabled={true}
      />

      <Field
        state={state.getValue}
        title="Enter Referral Code, if any"
        type="text"
        name="ref_code"
        required={true}
        disabled={true}
      />
      <div className="date-format">
        Submitted {result}
      </div>
    </>
  );
}
