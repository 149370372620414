import Toggle from "./toggle";
import Info from "./info";
import Route from "./Route";
import React, { createContext, useState,useEffect } from "react";

export const GlobalState = createContext();
export const Store = ({ children }) => {
  let info = new Info();
  let toggler = new Toggle();

  let headers = ["Contact Details", "Career and Skill", ""];
  let colors = {
    0: "#669df6",
    1: "#098591",
    2: "#34a853",
    3: "red",
    4: "gold"
  };
  let total = 5;
  let [page, setPage] = useState(0);
  let [mode, setMode] = useState(Route.MODE);
  let [toggle, setToggle] = useState({active:true,msg:""});
  let [percent, setPercent] = useState(100 / total);
  let [header, setHeader] = useState(headers[page]);
  let [date, setDate] = useState("10th October - 2022");
  let [tab, setTab] = useState("summary");
  let [input, setInput] = useState(1);
  let [emails, setEmail] = useState([]);
  let [users, setUser] = useState([]);
  let [main,setMain] = useState([])
  let [completed, setCompleted] = useState(false);
  let [title, setTitle] = useState("Course Registration Form");
  let [course,setCourse] = useState([])
  // let [csv,setCSV] = useState([])

  let [value, setValue] = useState(info.getData);

  toggler.setFunc(setToggle);


  useEffect(()=>{

    info.getUser.then(res => {      
      setUser(res)
      setMain(res)
    }).catch(err=>console.log(err))
    info.getEmail.then(res => setEmail(res)).catch(err=>console.log(err))
    info.getFormStatus.then(res => { setToggle(res) }).catch(err=>console.log(err))
    info.getCourses.then(res => { setCourse(res) }).catch(err=>console.log(err))
   
  
  },[])
  
  let state = {
    total,
    colors,
    headers,
    ingress: { page, setPage },
    progress: { percent, setPercent },
    getHeader: { header, setHeader },
    getDate: { date, setDate },
    getValue: { value, setValue },
    getCourse: { course, setCourse },
    getUser:{users,setUser},
    getMain:{ main,setMain },
    getEmail:{emails,setEmail},
    getCompleted:{completed,setCompleted},
    user_summary: info.summary(users),
    country:["Nigeria","Canada"],
    getMode: { mode, setMode },
    getTab: { tab, setTab },
    getTitle:{ title,setTitle},
    getInput:{input,setInput},
    getInfo: info,
    getToggle: { toggle, setToggle: toggler.setToggle }
  };

  return (
    <GlobalState.Provider value={state}> {children} </GlobalState.Provider>
  );
};
