import React, { useContext, useEffect, useState } from "react";
import Info from "../Context/info";
import { GlobalState } from "../Context/Store";

function ResponseHeader({ action }) {
  let state = useContext(GlobalState);
  let [accepted, setAccepted] = useState("wizbtn done");
  let { value,setValue } = state.getValue;

  let handleAction = (value) => {
    let info  = new Info();
    info.setStatus(value);
    let v = {...value};
    setValue(v)
  };

  useEffect(() => {
    let accept = value.generated ? "wizbtn done action-taken" : "wizbtn done";
    setAccepted(accept);
  }, [value]);

  return (
    <div className="left-text">
      <div className="solidbg height"></div>
      <div className="viewheader">
        <div className="desc">Responses cannot be edited</div>
        <div className="title">Course Registration Form</div>
        <div className="desc">Developing highly Skilled Tech Talent</div>
        <div className="legend"> * Required</div>
        {action === "individual" ? (
          <div className="flex row-reverse">
            <div></div>
            <div className="flex">
              <div className={accepted} onClick={() => handleAction(value)}>
                Generate
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
export default ResponseHeader;
