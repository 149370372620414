import React, { useContext, useEffect,useLayoutEffect,useState } from "react";
import { GlobalState } from "../Context/Store";
import { HotTable, HotColumn } from "@handsontable/react";
import {Helmet} from 'react-helmet/es/Helmet';
import {useCookies} from 'react-cookie';
import "handsontable/dist/handsontable.min.css";

export default function ExcelHandson() {
  let state = useContext(GlobalState);
  let info  = state.getInfo;
  let [headers,setHeader] = useState([])
  let [body,setBody] = useState([])
  let [cookies,setCookie] = useCookies([])

  useLayoutEffect(()=>{
    if(!cookies.user) window.location.href = "/login";
    setCookie('current_url', window.location.pathname, { path: "/" });
  },[])

  useEffect(() => {  
  
    info.getCSV().then(record => {
        let res = [...record];
        res.splice(0,1);
        setHeader(record[0]);
        setBody(res);        

    })    
  
  }, [info]);


  
  return (
    <div className="main-content">
      <Helmet>
        <title>Response-Sheet</title>
        <link rel="icon" href={"path"} />
        <meta name="description" content={"God is great"} />
        <meta name="keywords" content={"Friend and about"} />
        <meta property="og:title" content={"about me and the fellow"} />
        <meta property="og:description" content={"come oh human child"} />
      </Helmet>
      
    <HotTable 
        data={body}
        filters={true}
        height={1000}
        colWidths={[50,100, 100, 150, 290, 120, 70,390, 110, 30,500,100,200,190,300,100,100,100,100,100,900,290,170,170,100,170]}
        colHeaders={headers}
        licenseKey="non-commercial-and-evaluation"
        >         

     <HotColumn data={0} readOnly={true} className="htCenter"/>
     <HotColumn data={1} readOnly={true}/>
     <HotColumn data={2} readOnly={true}/>
      <HotColumn data={3} readOnly={true}/>
      <HotColumn data={4} readOnly={true}/>
      <HotColumn data={5} readOnly={true} className="htCenter"/>
      <HotColumn data={6} readOnly={true} className="htCenter"/>
      <HotColumn data={7} readOnly={true}/>
      <HotColumn data={8} readOnly={true} className="htCenter" />
      <HotColumn data={9} readOnly={true}/>
      <HotColumn data={10} readOnly={true}/>
      <HotColumn data={11} readOnly={true}/>
      <HotColumn data={12} readOnly={true}/>
      <HotColumn data={13} readOnly={true}/>
      <HotColumn data={14} readOnly={true}/>
      <HotColumn data={15} readOnly={true}/>
      <HotColumn data={16} readOnly={true}/>
      <HotColumn data={17} readOnly={true}/>
      <HotColumn data={18} readOnly={true}/>
      <HotColumn data={19} readOnly={true}/>
      <HotColumn data={20} readOnly={true}/>
      <HotColumn data={21} readOnly={true}/>
      <HotColumn data={22} readOnly={true}/>
      <HotColumn data={23} readOnly={true}/>
      <HotColumn data={24} readOnly={true} className="htCenter" />
      <HotColumn data={25} readOnly={true}/>
     

    </HotTable>
    </div>
  );
}
