import React, { useRef, useContext } from "react";
import { GlobalState } from "../Context/Store";
import AutoTextArea from "./Textarea";
import RoundChart from "./RoundChart";
import BarChart from "./BarChart";

const Control = ({
  type,  
  placeholder,
  name,
  occurence,
  data,
  disabled,
  className,
  defaultValue,
  value,
  onChange,
  onClick,
  legend,
  alias,
  labels,
  colors,
  required,
  state,
  id
}) => {
  let Ctrl = "";
  let select = useRef();
  let selected = useRef();
  
  let setValue = state?.setValue;
  let record = state?.value ? state.value : {}

  const handleClick = () => {
    select.current.classList.toggle("active");
  };

  const handleSelect = (e) => {
    let { innerText } = e.target;
    let name = e.target.getAttribute("name");
    if (name) {
      setValue({ ...record, [name]: innerText });
      if (onChange) onChange(innerText);
    }
  };

  const summarize = (data) => {
    let arr = {};
    for (let i = 0; i < data?.length; i++) {
      let d = data[i];
      if (arr[d]) {
        arr[d]++;
      } else {
        arr[d] = 1;
      }
    }
    return arr;
  }

  const handleChange = (e) => {
    let { name, value } = e;

    if (name) {
      setValue({ ...record, [name]: value });
    }
    if (onChange) onChange(value);
  };
  let d;
  let total;
  let hex = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6"];
  if (!colors) colors = hex;
  switch (type) {
    case "bar":

      if (data) {
        total = 0;
        d = {};
        data = summarize(data)
        for (let i = 0; i < labels.length; i++) {
          let label = labels[i];
          let count = 0;
          for (let j in data) {
            if (j === label) {
              count = data[label];
            }
          }
          d[label] = count;
          total += count;
        }

        if (total === 1) {
          total = total + " response";
        } else {
          total = total + " responses";
        }
        let _d = alias ? alias : Object.keys(d)

        Ctrl = (
          <>
            <div className="list-count">{total}</div>
            <div>
              <BarChart
                id={id}
                labels={_d}
                type={type}
                data={Object.values(d)}
                colors={colors}
              />
            </div>
          </>
        );
      } else {
        Ctrl = <></>
      }
      break;
    case "pie":
      if (data) {
        total = 0;
        d = {};
        data = summarize(data)
        for (let i = 0; i < labels.length; i++) {
          let label = labels[i];

          let count = 0;
          for (let j in data) {
            if (j === label) {
              count = data[label];
            }
          }
          d[label] = count;
          total += count;
        }

        if (total === 1) {
          total = total + " response";
        } else {
          total = total + " responses";
        }

        Ctrl = (
          <>
            <div className="list-count">{total}</div>
            <div>
              <RoundChart
                id={id}
                labels={Object.keys(d)}
                type={type}
                data={Object.values(d)}
                colors={colors}
              />
            </div>
          </>
        );
      }
      else {
        Ctrl = <></>
      }
      break;
    case "list":
      total = "";
      if (data) {
        if (data.length === 1) {
          total = data.length + " response";
        } else {
          total = data.length + " responses";
        }

        if(!onClick) onClick = (e,i)=> {}

        Ctrl = (
          <>
            <div className="list-count">{total}</div>
            {data.map((e, i) => {
              return (
                <div key={i} className="list" onClick={()=>onClick(e,i)}>
                  {e}
                </div>
              );
            })}
          </>
        );
      } else {
        Ctrl = <></>
      }

      break;
    case "checkbox":
      Ctrl = data.map((e, i) => {
        let id = i + e.value + e.name;
        return (
          <div className="form-group" key={e.name}>
            <input
              id={id}
              type="checkbox"
              defaultChecked={e.value}
              disabled={disabled}
            />
            <label htmlFor={id}>{e.name}</label>
            <input
              type="hidden"
              name={name}
              value={record ? record[name] : ""}
            />
          </div>
        );
      });
      break;
    case "radio":
      let RadArr = [];
      let Ctrl1 = (
        <div key={name}>
          <input type="hidden" name={name} value={record ? record[name] : ""} />
        </div>
      );
      let Ctrl2 = data.map((e, i) => {
        let id = i + e.value + e.name;
        let checked = "";
        if(record[name] === e.value) checked = "checked";
        if(e.disabled) disabled = e.disabled;

        return (
          <p className="m-10" key={id}>
            <input
              id={id}
              type="radio"
              checked={checked}
              name={name}
              onChange={(e) => handleChange(e.target)}
              value={e.value}
              disabled={disabled}
            />
            <label htmlFor={id}>{e.value}</label>
          </p>
        );
      });
      RadArr.push(Ctrl1, Ctrl2);
      Ctrl = <div>{RadArr}</div>;

      break;
    case "select":
      let initial = defaultValue ? defaultValue : "";
      let select_arr = [];
      let time = Date.now();
      let select1 = (
        <input
          type="hidden"
          key={name}
          name={name}
          value={record ? record[name] : initial}
        />
      );
     let options = data?.map((e, i) => {
        let id = i + e.value;
        return (
          <li
            key={id}
            name={name}
            role="option"
            onClick={disabled ? () => { } : (e) => handleSelect(e)}
            id={id}
            ref={selected}
            className="ng-binding ng-scope"
            aria-selected="true"
          >
            {e.value}
          </li>
        );
      });

      let select2 = (
        <div
          name={name}
          key={time}
          className="md-select"
          onClick={disabled ? () => { } : () => handleClick()}
          ref={select}
        >
          <label htmlFor="ul-id">
            <button type="button" className="ng-binding">
              {record ? record[name] : defaultValue}
            </button>
          </label>
          <ul
            role="listbox"
            id="ul-id"
            className="md-whiteframe-z1"
            name="ul-id"
          >
            <li
              key="unique"
              role="option"
              className="ng-binding ng-scope scope-first"
              aria-selected="true"
            >
              Choose
            </li>
            <div className="bar-line" key="bar-line"></div>
            {options}
          </ul>
        </div>
      );
      select_arr.push(select1, select2);
      Ctrl = <div>{select_arr}</div>;
      break;
    case "textarea":
      Ctrl = (
        <>
          <AutoTextArea
            name={name}
            value={record[name]}
            disabled={disabled}
            onChange={onChange}
          />
          <input type="hidden" name={name} value={record ? record[name] : ""} />
        </>
      );
      break;
    default:
      Ctrl = (
        <div>
          <input
            name={name}
            type={type}
            className="ttext"
            placeholder={placeholder}
            onChange={(e) => handleChange(e.target)}
            value={record[name]}
            disabled={disabled}
          />
          <label></label>
          <input type="hidden" name={name} value={record ? record[name] : ""} />
        </div>
      );
      break;
  }
  return Ctrl;
};

const Field = ({
  title,
  type,
  data,
  value,
  occurence,
  defaultValue,
  name,
  placeholder,
  required,
  disabled,
  className,
  def,
  onChange,
  onClick,
  legend,
  labels,
  alias,
  colors,
  datas,
  state,
  id
}) => {
  return (
    <>
      {!def ? (
        <div className="question input-box">
          <div className="question-header">
            <div className="item-title">
              {title}
              {required ? <span className="asterisk">*</span> : <></>}
            </div>
            <div className="textinput">
              <Control
                state={state}
                defaultValue={defaultValue}
                name={name}
                type={type}
                data={data}
                occurence={occurence}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                className={className}
                onChange={onChange}
                onClick={onClick}
                legend={legend}
                labels={labels}
                alias={alias}
                colors={colors}
                required={required}
                id={id}
                datas={datas}
              />
              <div className="validate-error">
                <div className="err-icon"></div> This is a required question
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Control
          state={state}
          name={name}
          type={type}
          occurenc={occurence}
          data={data}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          className={className}
          onChange={onChange}
          onClick={onClick}
          legend={legend}
          labels={labels}
          alias={alias}
          colors={colors}
          required={required}
          id={id}
          datas={datas}
        />
      )}
    </>
  );
};

export default Field;
