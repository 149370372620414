import React, { useContext, useEffect, useLayoutEffect } from "react";
import ResponseHeader from "../Component/ResponseInfo";
import Field from "../Component/Field";
import Summary from "../Pages/Summary";
import Individual from "../Pages/Individual";
import Question from "../Pages/Question";
import {Helmet} from 'react-helmet/es/Helmet';
import {useCookies} from 'react-cookie'

import { Link } from "react-router-dom";

import { GlobalState } from "../Context/Store";

function Tab({ tab }) {
  if (tab === "individual") return <Individual />;
  if (tab === "summary") return <Summary />;
  if (tab === "question") return <Question />;
}

export default function Response() {
  let state = useContext(GlobalState);

  let {course} = state.getCourse; 
  let { emails,setEmail } = state.getEmail;
  let { users, setUser } = state.getUser;
  let { value, setValue } = state.getValue;
  let { tab, setTab } = state.getTab;
  let { input, setInput} = state.getInput;
  let { toggle, setToggle } = state.getToggle;
  let { main,setMain } = state.getMain;
  let [cookies,setCookie,removeCookie] = useCookies([])

  let info = state.getInfo;
  let courses = [{ name:"0", value:"All Courses" }] 

  let handleChange = (e) => {

    let email = emails.find((x) => x.value === e);
    if (email) {
      let user = users.find((x) => x.id === email.id);
      let _user = {...user,email:e}

      setValue(_user);
      setInput(email.name);
    }
  };

  let handleInput = (id) => {

    if (id) {
      let email;
      let user;
      id = parseInt(id, 10);
      email = emails.find(x => x.name === id);
      setInput(id);
      
      if (email) {
        user = users.find((x) => x.id === email.id);
        if(user){
          let _user = {...user,email:email.value}
          setValue(_user);
        }
      }
    }
  }

  let handleDelete = () =>{
    let _emails;
    let _users;
    let response = window.confirm("Are you sure you want to delete ?");

    if(response) info.deleteFormResponse(value.id);
    
    _users = users.filter(x => x.id !==  value.id);
    _emails = emails.filter(x => x.id !==  value.id);
    
    setUser(_users);
    setMain(_users);
    setEmail(_emails);
    setValue(_users[0])
    setInput(1);
    
  }

  let handleCourse = (e)=>{

    let mails;
    let records = main.filter(x => x.course === e); 

    if(e === "All Courses"){
     
      mails = main.map((x,index) => {
        return {
          name:index + 1,
          id:x.id,
          value:x.email
        }
      })
      records = main;
      setUser(main);
     

    }else{
      mails = records.map((x,index) => {
        return {
          name:index + 1,
          id:x.id,
          value:x.email
        }
      })

    }
    
    setUser(records);
    setInput(1)
    setEmail(mails);

  }

  let download = ({file_name,data,file_type})=>{
    let blob = new Blob(data,{type:file_type});
    let a = document.createElement('a');
    let click = new MouseEvent('click',{
      view:window,
      bubbles:true,
      cancelable:true
    });
    a.download = file_name;
    a.href = window.URL.createObjectURL(blob);
    a.dispatchEvent(click);
    a.remove();
  }

  let handleDownLoad = async()=> {
    let data = "";
    let arr = await info.getCSV(); 
  
    arr.map(a => {
      return data += a.join(",")+"\n"
    })
    // console.log(data)
    download({
      file_name:"response.csv",
      file_type:"text/csv",
      data:[data]
    })
  }

  let moveRight = () => {
    let i = input;
    i++;
    if (i >= users.length) i = users.length;
    handleInput(i)
  };

  let moveLeft = () => {
    let i = input;
    i--;
    if (i <= 1) i = 1;
    handleInput(i);
  };
 
 
  course.map((e,i)=> courses.push({ name:i+1, value:e.name }))

  useLayoutEffect(()=>{
    if(!cookies.user) window.location = "/login"
    setCookie("current_url",window.location.pathname,{ path: "/" })
  },[])

  useEffect(() => {     
    if (emails?.length && value.email === "") {
      if (users[0]) setValue(users[0]);
    }  
   
  }, [emails])



  return (
    <>
        <Helmet>
        <title>Response-Summary</title>
        <link rel="icon" href={"path"} />
        <meta name="description" content={"God is great"} />
        <meta name="keywords" content={"Friend and about"} />
        <meta property="og:title" content={"about me and the fellow"} />
        <meta property="og:description" content={"come oh human child"} />
      </Helmet>
      <div className="App mt-115">
     
        <div className="left-text top-border ">
          <div className="flex  space-btw">
            <div className="res">{users.length} response</div>
            <div className="flex center h">
              <Link className="sheet" to="/sheet" target="_blank"></Link>
              <button className="more" onClick={handleDownLoad}></button>
            </div>
          </div>

          <div className={toggle?.active ? "toggle-response" : "toggle-response check"}>
            <section className="model-4">
              <div className="checkbox">
                <input
                  type="checkbox"
                  id="response"
                  checked={toggle?.active}
                  onChange={() => setToggle({ ...toggle, active: !toggle.active, mode: "save" })}
                />
                <label></label>
              </div>
            </section>
            <label htmlFor="response" className="res-label">
              {toggle?.active ? "Accepting responses" : "Not accepting responses"}
            </label>
          </div>
          <div>
            <div className="respondant-msg">Message for respondents</div>
            <div className="mt10 mb10">
              <input
                className="ttext no-border w100"
                placeholder="This form is no longer accepting responses"
                value={toggle.msg}
                name="msg"
                onBlur={(e) => {
                  setToggle({ ...toggle, ["msg"]: e.target.value, mode: "save" })
                }}
                onChange={(e) => setToggle({ ...toggle, [e.target.name]: e.target.value, mode: "write" })}
              />
              <label></label>
            </div>
          </div>
          <div className="flex">
            <div
              className={tab === "summary" ? "tab active" : "tab"}
              onClick={() => setTab("summary")}
            >
              <span>Summary</span>
            </div>
            <div
              className={tab === "question" ? "tab active" : "tab"}
              onClick={() => setTab("question")}
            >
              <span>Question</span>
            </div>
            <div
              className={tab === "individual" ? "tab active" : "tab"}
              onClick={() => setTab("individual")}
            >
              <span>Individual</span>
            </div>
          </div>
          {tab !== "individual" ? (
            <></>
          ) : (
            <div className="viewheader flex center remove-top-border border">
              <div style={{ width: "100%", display: "flex",alignItems: "center" }}>

                <Field
                  type="select"
                  name="email"
                  def="email"
                  data={emails}
                  state={state.getValue}
                  onChange={handleChange}
                />

                <div className="flex center h">
                  <button className="lt" onClick={moveLeft}></button>
                  <input
                    type="number"
                    className="pos-box"
                    onChange={(e) => handleInput(e.target.value)}
                    value={input}
                  />
                  of &nbsp;&nbsp;{users.length}
                  <button className="gt" onClick={moveRight}></button>
                </div>
              </div>

              <div className="flex center h">
                <button className="print" onClick={moveLeft}></button>
                <button className="delete" onClick={handleDelete}></button>
              </div>
            </div>
          )}
        </div>
        <div style={{ marginTop: "12px",marginBottom:"-18px"}}>
         

        </div>
        <ResponseHeader action={tab} />
        <Field
            type="select"
            name="course"
            defaultValue="All Courses"
            data={courses}
            state={state.getValue}
            onChange={handleCourse}
          />
        <Tab tab={tab} action={true} />
      </div>
    </>
  );
}
